export const REDUCER_KEY = 'mediaList';
import { BASE_URL } from './../../config';

export const DATA = 'data';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const MEDIA_LIST = 'mediaList';
export const MEDIA_LIST_API = `${BASE_URL}/acr/buckets/files/19017`;
// http://reelivtest-env.eba-pfzd3pfg.ap-south-1.elasticbeanstalk.com/acr/buckets/files/19017
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const SHOW_DELETE_FORM = 'showDeleteForm';
export const SELECTED_ROW_ID = 'selectedRowId';

export const mediaTableConfig = 
  {
    columns: [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      // {
      //   Header: 'ACR ID',
      //   accessor: 'acr_id',
      // },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'Duration',
        accessor: 'duration',
      },
      {
        Header: 'User Defained',
        accessor: (row) => row.user_defined && JSON.stringify(row.user_defined) || '---',
        id: 'user_defined',
        meta: {
          user_defined: true,
        }
      },
      {
        Header: 'Action',
      },
    ],
    options: {
      displayFilter: false,
      displaySearch: false
    }
  };

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [MEDIA_LIST]: [],
  [AUTHENTICATED_SESSION]: true,
  [SHOW_DELETE_FORM]: false,
  [SELECTED_ROW_ID]: ''
};

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
