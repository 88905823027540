import React from 'react';
import { Row, Col, Button, Card, CardHeader, CardBody, CardFooter} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const DeleteForm = (props) => {
  const {
    formData,
    actions,
    toggleDeleteForm,
    selectedRowId,
  } = props;

  const handleOnSubmitClick = (e) => {
    e.preventDefault();
    actions.deleteAudioForm(selectedRowId);
  }

  const handleOnCancelClick = (e) => {
    e.preventDefault();
    actions.toggleDeleteForm(false);
  }
  console.log('delete form props', props)
  return(
    <div className="delete-form">
      <Row>
        <Col>
          <div className="delete-header">
            Delete Confirmation
          <div className="close-icon">
            <FontAwesomeIcon onClick={toggleDeleteForm} icon={faTimes}></FontAwesomeIcon>
          </div>
          </div>
          <div className="content">
            Are you sure you want delete this data?
          </div>
          <div className="footer">
            <Button color='primary' onClick={handleOnSubmitClick} >Submit</Button>
            <Button color='secondary' onClick={handleOnCancelClick} >Cancel</Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default DeleteForm;
