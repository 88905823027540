import React from 'react';
import MenuList from './components/menuList';
import Header from './components/header';
import './index.scss';
export const navItems = [
  {
    linkTo: '#',
    icon: 'FileEarmarkMusic',
    title: 'Audio',
    subNav: [
      {
        linkTo: '/media-management',
        title: 'Add Audio',
      },
      {
        linkTo: '/media-list',
        title: 'Audio List',
      }
    ]
  },
];

export class Sidebar extends React.Component {
  static propTypes = {
    // username: PropTypes.string,
  }
  static defaultProps = {
    // username: '',
  }
  render() {
    return (
      <>
        <aside className="sidebar-wrapper" data-simplebar="init">
            <div className="simplebar-wrapper">
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer"></div>
              </div>
              <div className="simplebar-mask">
                <div className="simplebar-offset">
                  <div className="simplebar-content-wrapper">
                    <div className="simplebar-content mm-active">
                      <Header />
                      <MenuList menuList={navItems} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="simplebar-placeholder">
              </div>
            </div>
            <div className="simplebar-track simplebar-vertical">
              <div className="simplebar-scrollbar">
              </div>
            </div>
          </aside>
      </>
    )
  }
}

export default Sidebar;